/*
 * Created Date: December 24th 2024, 5:14:56 pm
 * Author: zhoupengcheng
 * -----
 * Last Modified: December 24th 2024, 6:19:12 pm
 */

import request, { api, BaseResponse } from '.';
import { IBasicMaterial } from './draw';
import { Status } from './tabiParty';

export interface MiniNodeShopInfo {
  start_ts: number;
  end_ts: number;
  u_ton_price: number;
}

export enum OrderStatus {
  PENDING = 'pending',
  PAID = 'paid',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export const getMiniNodeShopInfo = async () => {
  const res = await request<MiniNodeShopInfo>('get', 'shop/v1/query_shop_info');

  return res;
};

export interface MiniNode {
  id: number;
  goods_name: string;
  goods_desc: string;
  goods_icon: string;
  goods_tags: string[];
  goods_price: number;
  goods_origin_price: number;
  sort_order: number;
  status: OrderStatus;
  goods_id: number;
  order_id: string;
  amount: number;
  insert_tm: Date
  goods_num: number;
  reward_id: string,
  reward_type: string
}

export const getMiniNodeList = async () => {
  const res = await request<MiniNode[]>('get', 'shop/v1/list_shop_goods');

  return res;
};

export interface OrderResponse {
  order_id: string;
  payload: string;
  recipient_address: string;
  amount: number;
}

export const createMiniNodeOrder = async (params: {
  goods_id: number;
  num: number;
}) => {
  const res = await api
    .post('shop/v1/create_user_shop_order', {
      json: params,
    })
    .json<BaseResponse<OrderResponse>>();

  return res;
};

export const getMiniNodeOrder = async (
) => {
  const res: MiniNode[] = await request('get', 'shop/v1/list_user_shop_order');

  return res;
};

export interface IRangePointsConfig {
  advanced_material_id: number;
  range_points_amount: number
}

export const getMiniNodeRangePoints = async (
) => {
  const res: IRangePointsConfig[] = await request('get', 'synthesis/v1/rangepoints-config');

  return res;
};

export type StakeMaterials = Pick<IBasicMaterial, 'material_id' | 'quantity'>

export interface IStakeRequest {
  target_pool_id: number;
  materials: Array<StakeMaterials>
}

export interface IStakeResponse {
  stake_success: boolean;
  StakedMaterials: Array<StakeMaterials>
}

export const stakeMiniNode = async (body: IStakeRequest
) => {
  const res: IStakeResponse = await request('post', 'synthesis/v1/stake', { json: body });

  return res;
};

export interface IProjectDetail {
  project_name: string,
  begin_time: number,
  end_time: number,
  end_remark: string,
  is_unstaked: Status,
  mining_reward_per_hour: number,
  total_reward: number,
  total_rage_point: number
  project_mined: number
}

export const getProjectDetail = async (
) => {
  const res: IProjectDetail = await request('get', 'synthesis/v1/project-detail');

  return res;
};

export interface IStakeDetail {
  pool_id: number,
  pool_name: string,
  partner_logo_url: string,
  pool_description: string,
  partner_rebate_rate: number,
  lowest_material_id: number,
  remark: string,
  begin_time: number,
  end_time: number,
  user_pool_total_reward: string,
  pool_total_mining: string,
  pool_speed_per_hour: string,
  continue_stake: boolean,
  pool_rage_point: number,
  staking_rage_point: number,
  mini_node_infos: { [key: string]: number }
}


export const getStakeDetail = async (
) => {
  const res: IStakeDetail[] = await request('get', 'synthesis/v1/stake-detail');

  return res;
};

export interface IArenaHistory {
  project_id: number,
  project_name: string,
  total_rewards: number,
  obtain: string,
  participants: number,
  project_begin_time: Date,
  project_end_time: Date
}

export const getArenaHistory = async (
) => {
  const res: IArenaHistory[] = await request('get', 'synthesis/v1/project-history');
  return res;
};


export interface IHistory {
  pool_id: number,
  pool_name: string,
  obtain: number,
  project_end_time: Date
  project_id: number,
  project_name: string,
}

export const getHistory = async (
) => {
  const res: IHistory[] = await request('get', 'synthesis/v1/mining-history');
  return res;
};

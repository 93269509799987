import { getMiniNodeShopInfo } from '@/api/mininode';
import images from '@/const/images';
import useMiniNodeList from '@/hooks/useMiniNodeList';
import { useRequest } from 'ahooks';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

/*
 * Created Date: December 25th 2024, 8:54:46 pm
 * Author: zhoupengcheng
 * -----
 * Last Modified: December 25th 2024, 8:54:46 pm
 */
export default function Shop() {
  const { data: list } = useMiniNodeList();

  const { data } = useRequest(getMiniNodeShopInfo, {
    cacheKey: 'miniNodeShopInfo',
  });

  const timestamp = data?.end_ts || dayjs().add(1, 'day').unix();

  const isAfter = dayjs().isAfter(dayjs(timestamp * 1000));

  return (
    <Link to="/mini-node/sell">
      <div className="relative">
        <img
          src={images.mininode.miniNodeShop}
          alt=""
          className="w-[50px]"
          width={50}
          height={64}
        />
        <div className="absolute bottom-[8px] left-[14px] text-white text-center [-webkit-text-stroke-width:0.4px] [-webkit-text-stroke-color:#7775E8] font-changa-one text-[10px] font-normal leading-[normal] tracking-[-0.408px] capitalize">
          Shop
        </div>

        {list?.[0].goods_tags?.[0] && (
          <div
            className={clsx(
              'w-8 h-3 shrink-0 shadow-[0px_0.4px_1px_0px_rgba(0,0,0,0.34),0px_-1.2px_0px_0px_rgba(0,0,0,0.13)_inset] rounded-[6px_6px_6px_0px] absolute top-[2px] right-0 translate-x-[14px] text-white text-center font-changa-one text-[8px] font-normal leading-[10px] tracking-[-0.408px] capitalize',
              isAfter ? '[background:#00CD00]' : '[background:#F1322E]'
            )}
          >
            {list?.[0].goods_tags?.[0]} off
          </div>
        )}
      </div>
    </Link>
  );
}
